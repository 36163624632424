import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { isNull } from '../../../theme/utils/functions';

import isEmpty from "lodash/isEmpty";

import { BarraZoomWrapper, IconeBarraZoom } from './style';

const BarraZoom = ({ svgElement, localidade, qualMapaSeTrataParaZoomEDragLibD3LocalStorageString }) => {
  const d3TransformData = useRef(null);

  useEffect(() => {
    if (svgElement && isNull(d3TransformData.current)) {
      const mapData = window.localStorage.getItem(qualMapaSeTrataParaZoomEDragLibD3LocalStorageString);
      d3TransformData.current = isNull(mapData)
        ? window.d3.zoomIdentity
        : JSON.parse(mapData);

      window.localStorage.setItem(
        qualMapaSeTrataParaZoomEDragLibD3LocalStorageString,
        JSON.stringify(d3TransformData.current)
      );
    }
  }, [svgElement]);

	const applyTransform = () => {
		const { x, y, k } = d3TransformData.current;

    if (svgElement) {
      if (!isEmpty(localidade.uf) && !isEmpty(localidade.municipio)) {
        // Aplica transformação com reflexão vertical
        svgElement.current.style.transform = `
          translate(${x}px, ${-y}px) scale(${k}, ${-k})
        `;
      } else {
        // Aplica transformação normal
        svgElement.current.style.transform = `
          translate(${x}px, ${y}px) scale(${k})
        `;
      }

      window.localStorage.setItem(
        qualMapaSeTrataParaZoomEDragLibD3LocalStorageString,
        JSON.stringify(d3TransformData.current)
      );
    }
  };

  // Função para zoom in ou out
  const zoomed = (zoomIn) => {
    const mapData = window.localStorage.getItem(qualMapaSeTrataParaZoomEDragLibD3LocalStorageString);

    if (mapData) {
      d3TransformData.current = JSON.parse(mapData);
    }

    // Determina a nova escala
    const newK = zoomIn
      ? d3TransformData.current.k * 1.2
      : d3TransformData.current.k / 1.2;

    // Atualiza o estado de transformação
    d3TransformData.current = window.d3.zoomIdentity
      .translate(d3TransformData.current.x, d3TransformData.current.y)
      .scale(newK);

		applyTransform();
	};

	return (
		<BarraZoomWrapper>
			<div className='zoomBox'>
				<IconeBarraZoom onClick={() => zoomed(true)}>
					<svg className='svgBotaoZoom' alt='Aumentar zoom' width='20px' height='20px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M4 12H20M12 4V20' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</IconeBarraZoom>
			</div>

			<hr />

			<div className='zoomBox'>
				<IconeBarraZoom onClick={() => zoomed(false)}>
					<svg className='svgBotaoZoom' alt='Diminuir zoom' width='20px' height='20px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M6 12L18 12' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</IconeBarraZoom>
			</div>


		</BarraZoomWrapper>
	);
};

BarraZoom.propTypes = {
  onCallback: PropTypes.func,
  svgElement: PropTypes.shape({
    current: PropTypes.shape({
      style: PropTypes.shape({
        transform: PropTypes.string,
      }),
    }),
  }),
  getTranformParent: PropTypes.func,
  qualMapaSeTrataParaZoomEDragLibD3LocalStorageString: PropTypes.string,
};

export default BarraZoom;
