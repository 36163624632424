import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import breakpointsMedia from '../../../theme/utils/breakpointsMedia';

export const AutoCompleteSelectWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	gap: 8px;
	flex: 3.5;
	flex-direction: row;
	align-items: center;

	${breakpointsMedia({
		md: css`
		flex-direction: column;
		align-items: flex-start;
		`,
	})}

	.voltar-abrangencia {
		cursor: pointer;
		z-index: 12;
		position: absolute;
		right: 10px;
		top: 7px;

		${breakpointsMedia({
			md: css`
				right: 0;
				top: 50%;
				transform: translateY(-125%);
			`,
		})}

  	}

	svg.disabled {
		display: none !important;
	}
`;

export const InputAutoComplete = styled.input`
	width: 100%;
	padding: 7px;
	outline: none;
	font-size: 14px;
	/* border-radius: 6px; */
	padding-right: 30px;
	flex: auto;
	border: 1px solid '#D0E1E9';
	background-color: '#fff';
	color: '#000';
	height: 32px;
	background-repeat: no-repeat;
	background-position: right 10px center;
	background-size: 16px;
	position: relative;

	@media (min-width: 768px) {
		font-size: 16px;
		height: 38px;
		/* border-radius: 8px; */
	}
`;

export const DropdownSelectLista = styled.ul`
	position: absolute;
	width: 100%;
	max-height: 10rem;
	overflow-y: auto;
	list-style-type: none;
	top: 2.5rem;
	padding: 0;
	margin: 0;
	border: 1px solid '#D0E1E9';
	border-top: none;
	border-radius: 0 0 6px 6px;
	background-color: '#fff';
	z-index: 2;

	::-webkit-scrollbar {
		width: 8px; /* Largura da barra de rolagem */
	}

	::-webkit-scrollbar-track {
		background:rgb(156, 170, 176); /* Cor do trilho */
		border-radius: 5px; /* Arredondamento */
	}

	::-webkit-scrollbar-thumb {
		background: #FD541E; /* Cor do polegar */
		border-radius: 5px; /* Arredondamento */
	}

	::-webkit-scrollbar-thumb:hover {
		background:rgba(253, 86, 30, 0.91); /* Cor do polegar */
		cursor: grab;
	}

	@media screen and (max-width: 768px) {
        top: 2rem;
    }
`;

export const DropdownAutoCompleteItem = styled.li`
	padding: 8px;
	cursor: pointer;
	font-size: 14px;

	${breakpointsMedia({
		md: css`
		font-size: 16px;
		`,
	})}
`;

// export const Seta = ({ isInputComValorSelecionado }) => (
// 	<svg
// 		className={`${isInputComValorSelecionado ? 'disabled' : 'selects__seta'}`}
// 		width='18'
// 		height='11'
// 		viewBox='0 0 18 11'
// 		fill='none'
// 		xmlns='http://www.w3.org/2000/svg'
// 	>
// 		<path
// 			d='M1 1L9 9L17 1'
// 			strokeWidth='2'
// 		/>
// 	</svg>
// );

// Seta.propTypes = {
// 	isInputComValorSelecionado: PropTypes.bool,
// };
