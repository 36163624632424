import styled, { css } from 'styled-components';
import { breakpointsMediaHeight } from '../../theme/utils/breakpointsMedia';
import { get } from '../../theme/utils/functions';

export const MapaBrasilWrapper = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9AE0EC;
  overflow: hidden;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          svg {
            path {
              cursor: none;
              pointer-events: none;
              fill: #343a40;

              &.enabled {
                opacity: 1;
                cursor: pointer;
                pointer-events: all;
                fill: '#BAD6DB';
              }
            }
          }
        `
      : ''}

  svg {
    /* max-width: 99vw;
    max-height: 99vh; */
    stroke: #96abae;
    fill: #BAD6DB;
    z-index: 0;
    cursor: grab;
    stroke-width: 500;
    /* margin-top: ${({ $isMobile }) => ($isMobile ? '400px' : '0')}; */
    height: ${({ $isMobile }) => ($isMobile ? '40vh' : '100vh')};
    /* transform: scale(1.5); */

    ${breakpointsMediaHeight({
      sm: css`
        height: ${({ $isMobile }) => ($isMobile ? '380px' : '100vh')};
      `,
      lg: css`
        height: ${({ $isMobile }) => ($isMobile ? '420px' : '100vh')};
      `,
      // xl: css`
      //   height: ${({ $isMobile }) => ($isMobile ? '820px' : '100vh')};
      // `,
    })}

    .selecionado {
      fill: #d3a923;
      stroke: #FFD700;
      stroke-width: 500;
      fill-opacity: 1;
      stroke-opacity: 1;
    }

    .municipios-container {
        path.municipio {
            /* stroke: #0d0d0e; */
            opacity: 1;
            transition: fill .3s ease;
        } 
    }

    .unidades-federativas-container {
        /* path {
            stroke: #fff;
            stroke-width: 1.5;
        } */

        path:focus {
            outline: none;
            border: none;
        }

        path.unidade-federativa-nao-participa-segundo-turno {
            cursor: not-allowed;
        }
    }

    .descricao-principais-municipios-container {
        g.label-da-cidade {
            opacity: 0;
            visibility: hidden;
        }

        g.label-da-cidade.show {
            opacity: 1;
            visibility: visible;
        }

        text.unidade-federativa-text {
            opacity: 0;
            visibility: hidden;
        }

        text.unidade-federativa-text.show {
            opacity: 1;
            visibility: visible;
        }

        text.unidade-federativa-text {
            stroke-linejoin: round;
            fill: #fff;
            stroke: #000;
            stroke-width: 5;
            paint-order: stroke fill;
            font-size: 1rem;
        }

        text.unidade-federativa-text.unidade-federativa-text--DF{
            font-size: 0.60rem;
        }
    }
  }
`;

export const Teste = styled.div``;
