import React, {
    useState,
    useMemo,
    useRef,
    useCallback,
    useEffect,
} from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import { cloneDeep } from "lodash";
import isEmpty from "lodash/isEmpty";
import { isNull } from "../../theme/utils/functions";

//Libs
import ReactECharts from "echarts-for-react";
import getMunicipios from "./municipiosData.js";
import estadosSVGs from "./estadosSVGsData.js";

//Components
import Cabecalho, { TitleRow } from '../../components/cabecalho';
import Modal from '../../components/modal';
import ButtonImage from '../../components/buttonImage';
import ButtonVoltar from '../../components/buttonVoltar';
import Fade from '../../components/fadeIn';
import DetalharEmendas from '../../components/repassesFederais/detalharEmendas';
import Ranking from '../../components/repassesFederais/ranking';
import ComparativoEmendas from '../../components/repassesFederais/comparativoEmendas';
// import BarraFiltrosPorLocalidade from '../../components/repassesFederais/BarraFiltrosPorLocalidade';
import MapaBrasilComMunicipios from '../../components/MapaBrasilComMunicipios';
import BarraZoom from '../../components/repassesFederais/BarraZoom';
import AutoCompleteSelect from '../../components/repassesFederais/AutoCompleteSelect';
import BotaoVoltarNivel from '../../components/repassesFederais/BotaoVoltarNivel';

//Icons
import populacaoIcon from "./ico/icon-populacao.svg";
import municipioIcon from "./ico/icon-municipio.svg";
import cifraoIcon from "./ico/icon-cifrao.svg";

//Images
import congressista1 from "./img/image-1.png";
import congressista2 from "./img/image-2.png";
import congressista3 from "./img/image-3.png";
import graficoMesorregiao from "./img/grafico-mesorregiao.svg";
import localizacaoFigure from "./img/illustration-maps.svg";

//Styles
import Styles from "./index.module.scss";
import {
    BaseButton,
    Button,
    FiltroButtons,
    FiltroRepasses,
    Repasses,
    Charts,
    Mesorregiao,
    RepassesFooter,
    MapaWrapper,
    AutoCompleteSelectBurcarMunicipioWrapper,
    RepassesAside,
    ContentWrapper,
    AsideInfo,
    AsideBox,
    AsideTitle,
    AsideLista,
    AsideListaItem,
    AsideBottom,
    AsideRightComponentes,
    AsideRightComponentesBottom,
    BotaoSelectAnoWrapper,
    AsideRightBox,
    CongressistasRanking,
    AsideRightBoxCongressistasRanking,
    CongressistasTitulo,
    CongressistasLista,
    CongressistasListaItem,
    MenuSeletoFiltro,
} from "./styles.js";

//Hooks
import { useApiRepasses } from "../../hooks/useApiRepasses.js";

import { MapaBrasilWrapper } from "../../components/MapaBrasilComMunicipios/styles";

import {
    MapaEstadoWrapper,
    // MapaMunicipioWrapper,
    MapaDragWrapper,
} from "../../components/MapaEstados/styles";
import CongressistasRankingCarousel from "../../components/repassesFederais/CongressistasRankingCarousel/index.js";

const INITIAL_STATE = {
    mostrarModal: true,
    mostrarModalRelatorio: false,
    abaRepasses: 0,
    anoRepasses: 2024,
    localidade: {
        uf: null,
        municipio: {
            label: "",
            value: 0,
            nome: "",
            uf: "",
        },
    },
    localidadeComparacao: {
        uf: null,
        municipio: {
            label: "",
            value: 0,
            nome: "",
            uf: "",
        },
    },
    idDoEstadoClicado: null,
    mostrarMapaDoBrasil: true,
    mostrarMapaSvgEstadoClicado: false,
    municipiosDoEstadoSelecionado: [],
    carregando: 0,
};

const ModelGerarRelatorio = ({mostrarModalRelatorio, setMostrarModalRelatorio, localidade, localidadeComparacao, anoConsulta}) => {
    const api = useApiRepasses();

    const [dadosGeracao, setDadosGeracao] = useState({
        tipoRelatorio: 0,
        tipoArquivo: 0,
        ano: 0,
        idLocalidade: 0,
        nomeLocalidade: "",
        idLocalidade2: 0,
        nomeLocalidade2: "",
    });
    const { tipoRelatorio, tipoArquivo, ano, idLocalidade, nomeLocalidade, idLocalidade2, nomeLocalidade2 } = dadosGeracao;

    const requisitaRelatorio = async () => {
        const response = await api.gerarRelatorio(idLocalidade, ano, tipoArquivo, tipoRelatorio, nomeLocalidade, idLocalidade2, nomeLocalidade2);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let nome_arquivo = `relatorio_${nomeLocalidade.replaceAll(' ', '_').toLowerCase()}`;
        if(response.data.type.includes('csv'))
            link.download = nome_arquivo+'.csv';
        else if(response.data.type.includes('pdf'))
            link.download = nome_arquivo+'.pdf';
        else if(response.data.type.includes('zip'))
            link.download = nome_arquivo+'.zip';
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    const onSubmit = () => {
        requisitaRelatorio();
    };

    const onInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        setDadosGeracao(state => ({...state, [name]: parseInt(value)}));
    };

    useEffect(() => {
        setDadosGeracao(state => ({
            ...state,
            idLocalidade: localidade.municipio?.value || localidade.uf?.id_uf_ibge || 0,
            nomeLocalidade: localidade.municipio?.nome || localidade.uf?.descricao || "",
            idLocalidade2: localidadeComparacao.municipio?.value || localidadeComparacao.uf?.id_uf_ibge || 0,
            nomeLocalidade2: localidadeComparacao.municipio?.nome || localidadeComparacao.uf?.descricao || "",
        })); 
    }, [localidade, localidadeComparacao]);

    useEffect(() => {
        setDadosGeracao(state => ({
            ...state,
            ano: anoConsulta
        }));
    }, [anoConsulta]);

    return (
        <Modal
            active={mostrarModalRelatorio}
            setActive={setMostrarModalRelatorio}
        >
            <fieldset className={Styles.modalBodyRelatorio}>
                <div className={Styles.modalHeaderRelatorio}>
                    <p>gerar relatório</p>
                    <ButtonImage
                        image={"Fechar"}
                        onClick={() => setMostrarModalRelatorio(false)}
                    />
                </div>

                <div className={`${Styles.checkBoxAll}`}>
                    <div className={`${Styles.boxCheckbox}`}>
                        <input type="checkbox" name="tipoRelatorio" value={1} onClick={onInputChange} checked={tipoRelatorio == 1 ? true : false}/>
                        <label>completo</label>
                    </div>
                    <div className={`${Styles.boxCheckbox}`}>
                        <input type="checkbox" name="tipoRelatorio" value={2} onClick={onInputChange} checked={tipoRelatorio == 2 ? true : false} disabled={(localidadeComparacao.municipio?.value || localidadeComparacao.uf?.id_uf_ibge) ? false : 'disabled'} />
                        <label>comparação</label>
                    </div>
                    <div className={`${Styles.boxCheckbox}`}>
                        <input type="checkbox" name="tipoRelatorio" value={3} onClick={onInputChange} checked={tipoRelatorio == 3 ? true : false}/>
                        <label>detalhamento das emendas</label>
                    </div>
                </div>

                <div>
                    <div className={Styles.archiveTitle}>
                        <span>tipo de arquivo</span>
                    </div>

                    <div className={`${Styles.checkBoxAll}`}>
                        <div className={`${Styles.boxCheckbox}`}>
                            <input type="checkbox" name="tipoArquivo" value={1} onClick={onInputChange} checked={tipoArquivo == 1 ? true : false}/>
                            <label>PDF</label>
                        </div>
                        <div className={`${Styles.boxCheckbox}`}>
                            <input type="checkbox" name="tipoArquivo" value={2} onClick={onInputChange} checked={tipoArquivo == 2 ? true : false}/>
                            <label>CSV</label>
                        </div>
                        <div className={`${Styles.boxCheckbox}`}>
                            <input type="checkbox" name="tipoArquivo" value={3} onClick={onInputChange} checked={tipoArquivo == 3 ? true : false}/>
                            <label>ambos os arquivos (PDF e CSV)</label>
                        </div>
                    </div>
                </div>

                <div className={Styles.modalInfoRelatorio}>
                    <button>

                        <a onClick={onSubmit}>Gerar relatório</a>
                    </button>
                </div>
            </fieldset>
        </Modal>
    )
}

const MesorregiaoContainer = ({ idMesorregiao }) => {
	const api = useApiRepasses();
	const [dadosMesorregiao, setDadosMesorregiao] = useState({
        nome: '',
        municipios: 0,
        habitantes: 0,
        valorEmpenhado: 0,
        valorPago: 0,
        donutChartOption: { //Gráfico de Donut
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b}: {c} ({d}%)",
            },
            grid: {
                left: "0%",
                right: "0%",
                bottom: "3%",
                containLabel: true,
            },
            legend: {
                orient: "vertical",
                left: "65%",
                top: "15%",
                itemWidth: 12,
                itemHeight: 12,
                icon: "circle",
                width: 100
            },
            series: [
                {
                    name: "Categorias",
                    type: "pie",
                    radius: ["35%", "70%"],
                    center: ["35%", "50%"],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 0,
                        borderColor: "#fff",
                        borderWidth: 2,
                    },
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: true,
                    },
                    data: [],
                },
            ],
        }
    });
	const [errorFoto, setErrorFoto] = useState(false);

	const consultaMesorregiao = async (id) => {
        try {
			const response = await api.consultaMesorregiao(id);
			setDadosMesorregiao(state => ({
                ...state,
                ...response,
                donutChartOption: {
                    ...state.donutChartOption,
                    series: {
                        ...state.donutChartOption.series,
                        data: response.donutChartOption.seriesData
                    }
                }
            }));
			
			// console.log("deu certo", response);
		} catch (error) {
			setDadosMesorregiao({
				nome: '',
				municipios: 0,
				habitantes: 0,
				valorEmpenhado: 0,
				valorPago: 0,
				donutChartOption: { //Gráfico de Donut
					tooltip: {
						trigger: "item",
						formatter: "{a} <br/>{b}: {c} ({d}%)",
					},
					grid: {
						left: "0%",
						right: "0%",
						bottom: "3%",
						containLabel: true,
					},
					legend: {
						orient: "vertical",
						left: "65%",
						top: "15%",
						itemWidth: 12,
						itemHeight: 12,
						icon: "circle",
						width: 100
					},
					series: [
						{
							name: "Categorias",
							type: "pie",
							radius: ["35%", "70%"],
							center: ["35%", "50%"],
							avoidLabelOverlap: false,
							itemStyle: {
								borderRadius: 0,
								borderColor: "#fff",
								borderWidth: 2,
							},
							label: {
								show: false,
							},
							labelLine: {
								show: true,
							},
							data: [],
						},
					],
				}
			});
		}
    };

	useEffect(() => {
		if(idMesorregiao){
			setErrorFoto(false);
			consultaMesorregiao(idMesorregiao);
		}
	}, [idMesorregiao]);

	return (
		<section className={Styles.mesorregiaoContainer}>
			<header>
				<h3>Total por mesorregião {dadosMesorregiao.nome}</h3>
			</header>

			<div className={Styles.mesorregiaoBox}>
				<div className={Styles.mesorregiaoChild}>
					{errorFoto ? (
						<img
							src={graficoMesorregiao}
							alt={`Mesorregião ${dadosMesorregiao.nome}`}
						/>
					) : (
						<img
							src={`https://monitor-static.poder360.com.br/static?path=repasses/img/mesorregiao/${idMesorregiao}.svg`}
							alt={`Mesorregião ${dadosMesorregiao.nome}`}
							onError={() => setErrorFoto(true)}
						/>
					)}

					<ul>
						<li>
							<span>{dadosMesorregiao.municipios}</span>
							<p>municípios</p>
						</li>

						<li>
							<span>{dadosMesorregiao.habitantes.toLocaleString('pt-BR')}</span>
							<p>habitantes</p>
						</li>

						<li>
							<span>{dadosMesorregiao.valorEmpenhado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
							<p>valor empenhado</p>
						</li>

						<li>
							<span>{dadosMesorregiao.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
							<p>emendas pagas</p>
						</li>

						{/* <li>
							<span>R$ 12.647.917,66</span>
							<p>emendas pagas</p>
						</li> */}
					</ul>
				</div>

				<div className={Styles.donutChart}>
					<header>
						<h3>Distribuição por área de atuação</h3>
					</header>
					<ReactECharts option={dadosMesorregiao.donutChartOption} style={{ width: "100%", height: "350px" }} />
				</div>
			</div>
		</section>
	);
};

const RepassesFederais = ({ componente }) => {
    const [stateLocal, setStateLocal] = useState(cloneDeep({
        ...INITIAL_STATE,
        abaRepasses: componente
    }));

    const {
        mostrarModal,
        mostrarModalRelatorio,
        abaRepasses,
        anoRepasses,
        localidade,
        localidadeComparacao,
        idDoEstadoClicado,
        mostrarMapaDoBrasil,
        mostrarMapaSvgEstadoClicado,
        municipiosDoEstadoSelecionado,
        carregando
    } = stateLocal;

	//api de listagem repasses
	const { id } = useParams();
	const api = useApiRepasses();
	const [repasses, setRepasses] = useState({});
	const [lineChartOption, setLineChartOption] = useState({ //Gráfico de linhas
		tooltip: {
			trigger: "axis",
            formatter: (params) => {
                return `${params[0].name}<br/>` + params.filter(param => typeof param.data == 'number').sort().reverse().map(param => `${param.marker} <b>${param.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b>`).join('<br />');
            }
		},
		grid: {
			top: '5%',
		},
		xAxis: {
			type: "category",
			data: [],
			boundaryGap: false,
			axisLine: {
				lineStyle: {
					color: "#EAECEE",
					width: 1,
				},
			},
			axisLabel: {
				color: "#7C828A",
				fontSize: 12,
			},
		},
		yAxis: {
			type: "value",
			min: 0,
			max: 0,
			interval: 200000,
		},
		series: [
			{
				data: [],
				type: "line",
				areaStyle: {
					color: {
						type: 'linear',
						x: 0,
						y: 0,
						x2: 0,
						y2: 1,
						colorStops: [
							{
								offset: 0, color: 'rgba(0, 102, 255, 0.5)'
							},
							{
								offset: 1, color: 'rgba(0, 102, 255, 0)'
							}
						],
						global: false
					}
				},

				smooth: false,
				lineStyle: {
					color: "#4040F2",
					width: 2,
				},
				symbol: "circle",
				symbolSize: 8,
				color: "#2323BE",
			},
		],
	});
	const [barChartOption, setBarChartOption] = useState({ //Gráfico de barras
		tooltip: {
			trigger: "axis",
			axisPointer: {
				type: "shadow",
			},
            formatter: (params) => {
                return `${params[0].name}<br/>` + params.filter(param => typeof param.data[1] == 'number').sort().reverse().map(param => `${param.marker} <b>${param.value[1]}</b>`).join('<br />');
            }
		},
		dataset: {
			source: [
				["score", "amount", "product"],
				[0, 0, "Impositivas"],
				[0, 0, "Relator"],
				[0, 0, "Comissão"],
				[0, 0, "Bancada"],
				[0, 0, "Individuais"],
			],
		},
		grid: {
			left: "5%",
			right: "5%",
			top: "5%",
			bottom: "5%",
			containLabel: true,
		},
		xAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: "#EAECEE",
					width: 1,
				},
			},
			axisLabel: {
				show: false,
				color: "#7C828A",
				fontSize: 12,
			},
			splitLine: {
				show: false,
			},
		},
		yAxis: {
			type: "category",
			axisLine: {
				show: true,
				lineStyle: {
					color: "#EAECEE",
					width: 1,
				},
			},
			axisLabel: {
				formatter: (value) => value,
				color: "#7C828A",
				fontSize: 12,
			},
		},
		series: [
			{
				type: "bar",
				encode: {
					x: "amount",
					y: "product",
				},
				label: {
					show: true,
					position: "right",
					formatter: (params) => {
						const amount = params.data[0]; // O valor numérico está na coluna "amount"
						return `${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`; // Formata como moeda
					},
					color: "#7C828A",
				},
				itemStyle: {
					color: (params) => {
						const colorList = [
							"#6E6EF7",
							"#4040F2",
							"#3333D1",
							"#181894",
							"#0D0D54",
						];
						return colorList[params.dataIndex % colorList.length];
					},
				},
				barWidth: "50%", // Ajuste a largura das barras aqui
			},
		],
	}); 

	// MOCK 

	const congressistasRankingListMock = [
		{
			img: congressista1,
			posicao: '1º',
			nome: 'Wilder Morais',
			partido: 'PL/GO',
		},
		{
			img: congressista2,
			posicao: '2º',
			nome: 'Flávia Morais',
			partido: 'PDT/GO',
		},
		{
			img: congressista3,
			posicao: '3º',
			nome: 'Adriana Accorsi',
			partido: 'PT/GO',
		},
	];

	const arredondaNumero = (numero) => {
		let numeroAjustado = Math.ceil(numero);
		const tamanhoZeros = numeroAjustado.toString().length - 1;
		let numeroSoma = parseInt(numeroAjustado.toString()[0]) + 1;
		for (var i = 0; i < tamanhoZeros; i++) numeroSoma += '0';
		const diferenca = numeroSoma - numeroAjustado;
		return numeroAjustado + diferenca;
	};

	const consultaListagemRepasses = async (id, ano) => {
		try {
			const response = await api.listagemLocalidades(id, ano);
			setRepasses(response);
			setLineChartOption(state => ({
				...state,
				xAxis: {
					...state.xAxis,
					data: response.evolucao_emendas_pagas.xAxisData
				},
				yAxis: {
					...state.yAxis,
					max: arredondaNumero(Math.max(...response.evolucao_emendas_pagas.seriesData) + 1),
					interval: arredondaNumero(Math.max(...response.evolucao_emendas_pagas.seriesData) / 7)
				},
				series: [
					{
						...state.series[0],
						data: response.evolucao_emendas_pagas.seriesData
					}
				]
			}));
			setBarChartOption(state => ({
				...state,
				dataset: {
					...state.dataset,
					source: [
						["score", "amount", "product"],
						...response.tiposEmendas
					]
				}
			}));
			// console.log("deu certo", response);
		} catch (error) {
			// console.error("deu errado", error);
		}
	};

	const svgBrasilMapRef = useRef(null);
	const svgEstadoMapRef = useRef(null);
	const d3TranformMapaRef = useRef(null);
    const localidadeRef = useRef(null);

    useEffect(() => {
        // Atualiza a ref sempre que localidade for alterada
        // Devido a problemas do proprio react ao lidar com useStates em escopos com useCallback
        // Útil para cenários aonde o acesso ao useState é null, ou não esta refletindo o current state corretamente.
        localidadeRef.current = localidade;
    }, [localidade]);

    const navigate = useNavigate();
    const location = useLocation();

    const setMostrarModal = (value) => {
        setStateLocal((state) => ({ ...state, mostrarModal: value }));
    };

    const setMostrarModalRelatorio = (value) => {
        setStateLocal((state) => ({ ...state, mostrarModalRelatorio: value }));
    };

    // @moacirdavidag: Criei esse estado só para controlar a responsividade
    // mas podemos criar um custom hook para toda a aplicação depois
    // isso é provisório
    // se já houver e eu não tiver encontrado, perdão, peço feedbacks
    const [isTelaMobile, setIsTelaMobile] = useState(false);

    const estados = useMemo(
        () => [
            { uf: "ac", descricao: "Acre", id_uf_ibge: "12" },
            { uf: "al", descricao: "Alagoas", id_uf_ibge: "27" },
            { uf: "ap", descricao: "Amapá", id_uf_ibge: "16" },
            { uf: "am", descricao: "Amazonas", id_uf_ibge: "13" },
            { uf: "ba", descricao: "Bahia", id_uf_ibge: "29" },
            { uf: "ce", descricao: "Ceará", id_uf_ibge: "23" },
            { uf: "df", descricao: "Distrito Federal", id_uf_ibge: "53" },
            { uf: "es", descricao: "Espírito Santo", id_uf_ibge: "32" },
            { uf: "go", descricao: "Goiás", id_uf_ibge: "52" },
            { uf: "ma", descricao: "Maranhão", id_uf_ibge: "21" },
            { uf: "mt", descricao: "Mato Grosso", id_uf_ibge: "51" },
            { uf: "ms", descricao: "Mato Grosso do Sul", id_uf_ibge: "50" },
            { uf: "mg", descricao: "Minas Gerais", id_uf_ibge: "31" },
            { uf: "pa", descricao: "Pará", id_uf_ibge: "15" },
            { uf: "pb", descricao: "Paraíba", id_uf_ibge: "25" },
            { uf: "pr", descricao: "Paraná", id_uf_ibge: "41" },
            { uf: "pe", descricao: "Pernambuco", id_uf_ibge: "26" },
            { uf: "pi", descricao: "Piauí", id_uf_ibge: "22" },
            { uf: "rj", descricao: "Rio de Janeiro", id_uf_ibge: "33" },
            { uf: "rn", descricao: "Rio Grande do Norte", id_uf_ibge: "24" },
            { uf: "rs", descricao: "Rio Grande do Sul", id_uf_ibge: "43" },
            { uf: "ro", descricao: "Rondônia", id_uf_ibge: "11" },
            { uf: "rr", descricao: "Roraima", id_uf_ibge: "14" },
            { uf: "sc", descricao: "Santa Catarina", id_uf_ibge: "42" },
            { uf: "sp", descricao: "São Paulo", id_uf_ibge: "35" },
            { uf: "se", descricao: "Sergipe", id_uf_ibge: "28" },
            { uf: "to", descricao: "Tocantins", id_uf_ibge: "17" },
            // { uf: 'exterior', descricao: 'Exterior', id_uf_ibge: '1' },
        ],
        []
    );

    const municipios = useMemo(() => getMunicipios());

    // const handleEstadoChange = (event) => {
    //     // setReactTooltipContent(null);

    //     // Tirei o JSON.parse porque o select pesquisável passa o objeto diretamente
    //     const estadoObj = JSON.parse(event.target.value);

    //     sincronizaUseStatesDeEstadoUFSelecionado({
    //         estadoObj,
    //     });
    // };

	const handleMapaZoomEDrag = useCallback(({ mapSvgRef }) => {
        window.localStorage.setItem(
            "map-zoom",
            JSON.stringify(window.d3.zoomIdentity)
        );

        if (isEmpty(mapSvgRef.current)) return;

        const applyTransform = () => {
            const { x, y, k } = d3TranformMapaRef.current;
          
            if (mapSvgRef.current) {

              if (!isEmpty(localidadeRef.current.uf) && !isEmpty(localidadeRef.current.municipio)) {
                // Aplica transformação com reflexão vertical (dois valores no scale)
                mapSvgRef.current.style.transform = `
                  translate(${x}px, ${-y}px) scale(${k}, ${-k})
                `;
              } else {
                // Aplica transformação normal (single scale)
                mapSvgRef.current.style.transform = `
                  translate(${x}px, ${y}px) scale(${k})
                `;
              }
          
              // Salva a transformação no localStorage
              window.localStorage.setItem(
                "map-zoom",
                JSON.stringify(d3TranformMapaRef.current)
              );
            }
          };

        const zoomed = (event) => {
            // Aborta a requisição do tooltip enquanto usuário esta arrastando a tela
            //   // eslint-disable-next-line no-use-before-define
            //   handleMouseEnterDebounced.cancel();
            //   // eslint-disable-next-line no-use-before-define
            //   handleMouseEnterDebouncedParaTooltipNivelAbrangenciaUnidadeFederativa.cancel();
            //   // eslint-disable-next-line no-use-before-define
            //   handleMouseEnterDebouncedParaTooltipNivelAbrangenciaZonaEleitoral.cancel();

            //   if (fetchAbortControllerTooltipRef.current) {
            //     fetchAbortControllerTooltipRef.current.abort();
            //   }
            //   // eslint-disable-next-line no-use-before-define
            //   setReactTooltipContent(null);

            const mapData = window.localStorage.getItem("map-zoom");
            d3TranformMapaRef.current = isNull(mapData)
              ? window.d3.zoomIdentity
              : JSON.parse(mapData);
            const svg = mapSvgRef.current;
            const [mouseX, mouseY] = window.d3.pointer(event, svg);
          
            // Nova escala de zoom
            const newK = event.transform.k;
          
            // Calcular a nova translação mantendo o ponto de foco
            const newX =
                mouseX -
                (mouseX - d3TranformMapaRef.current.x) *
                (newK / d3TranformMapaRef.current.k);
            const newY =
                mouseY -
                (mouseY - d3TranformMapaRef.current.y) *
                (newK / d3TranformMapaRef.current.k);
          
            // Atualizar a transformação
            d3TranformMapaRef.current = window.d3.zoomIdentity
              .translate(newX, newY)
              .scale(newK);
          
            applyTransform();
          };

          const dragged = (event) => {
            // Aborta a requisição do tooltip enquanto usuário esta arrastando a tela
            // eslint-disable-next-line no-use-before-define
            //   handleMouseEnterDebounced.cancel();
            //   // eslint-disable-next-line no-use-before-define
            //   handleMouseEnterDebouncedParaTooltipNivelAbrangenciaUnidadeFederativa.cancel();
            //   // eslint-disable-next-line no-use-before-define
            //   handleMouseEnterDebouncedParaTooltipNivelAbrangenciaZonaEleitoral.cancel();

            //   if (fetchAbortControllerTooltipRef.current) {
            //     fetchAbortControllerTooltipRef.current.abort();
            //   }
            //   // eslint-disable-next-line no-use-before-define
            //   setReactTooltipContent(null);

            const mapData = window.localStorage.getItem("map-zoom");
            d3TranformMapaRef.current = isNull(mapData)
              ? window.d3.zoomIdentity
              : JSON.parse(mapData);
            // Manter a escala atual e aplicar a nova translação
            const newX = d3TranformMapaRef.current.x + event.dx;

            // Necessário por conta da nova implementação de zoom no município selecionado, para melhor UX.
            // Essa implementação estava deixando o mapa de ponta cabeça.
            let newY;
            if (!isEmpty(localidadeRef.current.uf) && !isEmpty(localidadeRef.current.municipio)) {
                newY = d3TranformMapaRef.current.y - event.dy;
            } else {
                newY = d3TranformMapaRef.current.y + event.dy;
            }

            d3TranformMapaRef.current = window.d3.zoomIdentity
              .translate(newX, newY)
              .scale(d3TranformMapaRef.current.k);
            applyTransform();
          };

        const zoom = window.d3.zoom().touchable(true).on("zoom", zoomed);

        const dragstarted = () => {
            window.d3.select(this).raise();
            // eslint-disable-next-line no-param-reassign
            mapSvgRef.current.style.cursor = "grabbing";
        };

        const dragended = () => {
            // eslint-disable-next-line no-param-reassign
            mapSvgRef.current.style.cursor = "grab";
        };

        const drag = window.d3
            .drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);

        window.d3.select(mapSvgRef.current).call(drag).call(zoom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    // Função para obter municípios por UF
    const filtrarMunicipiosDoEstadoSelecionadoPorUF = useCallback(
        (uf) => {
            const municipiosDaUF = municipios?.filter(
                (mu) => mu.uf.toLowerCase() === uf.toLowerCase()
            );

            setStateLocal((state) => ({
                ...state,
                municipiosDoEstadoSelecionado: municipiosDaUF,
            }));
        },
        [municipios]
    );

    // Função para sincronizar o estado selecionado no painel, com o mapa, e vice-versa
    const sincronizaUseStatesDeEstadoUFSelecionado = useCallback(
        async ({ estadoObj }) => {
            const query = new URLSearchParams(location.search);
            const estadoQueryParam = query.get("estado");

            if (isEmpty(estadoObj)) {
                setStateLocal((state) => ({
                    ...state,
                    idDoEstadoClicado: null,
                    mostrarMapaDoBrasil: true,
                    mostrarMapaSvgEstadoClicado: false,
                    municipiosDoEstadoSelecionado: [],
                    // listaCandidatosApuracaoPorLocalidade: [],
                    // listaPrefeiturasPorPartido: [],
                    // zonasDoMunicipioSelecionadoTSEConfigData: [],
                    // apuracaoPorCargoDoMunicipioSelecionadoTSE: {},
                    // municipiosDoEstadoSelecionadoTSEConfigData: [],
                    localidade: {
                        ...localidade,
                        uf: null,
                        municipio: null,
                    },
                }));
                
                setRepasses({});
                setLineChartOption({ //Gráfico de linhas
                    tooltip: {
                        trigger: "axis",
                    },
                    grid: {
                        top: '5%',
                    },
                    xAxis: {
                        type: "category",
                        data: [],
                        boundaryGap: false,
                        axisLine: {
                            lineStyle: {
                                color: "#EAECEE",
                                width: 1,
                            },
                        },
                        axisLabel: {
                            color: "#7C828A",
                            fontSize: 12,
                        },
                    },
                    yAxis: {
                        type: "value",
                        min: 0,
                        max: 0,
                        interval: 200000,
                    },
                    series: [
                        {
                            data: [],
                            type: "line",
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0, color: 'rgba(0, 102, 255, 0.5)'
                                        },
                                        {
                                            offset: 1, color: 'rgba(0, 102, 255, 0)'
                                        }
                                    ],
                                    global: false
                                }
                            },
            
                            smooth: false,
                            lineStyle: {
                                color: "#4040F2",
                                width: 2,
                            },
                            symbol: "circle",
                            symbolSize: 8,
                            color: "#2323BE",
                        },
                    ],
                });

                setBarChartOption({ //Gráfico de barras
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                        },
                    },
                    dataset: {
                        source: [
                            ["score", "amount", "product"],
                            [0, 0, "Impositivas"],
                            [0, 0, "Relator"],
                            [0, 0, "Comissão"],
                            [0, 0, "Bancada"],
                            [0, 0, "Individuais"],
                        ],
                    },
                    grid: {
                        left: "5%",
                        right: "5%",
                        top: "5%",
                        bottom: "5%",
                        containLabel: true,
                    },
                    xAxis: {
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#EAECEE",
                                width: 1,
                            },
                        },
                        axisLabel: {
                            show: false,
                            color: "#7C828A",
                            fontSize: 12,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    yAxis: {
                        type: "category",
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#EAECEE",
                                width: 1,
                            },
                        },
                        axisLabel: {
                            formatter: (value) => value,
                            color: "#7C828A",
                            fontSize: 12,
                        },
                    },
                    series: [
                        {
                            type: "bar",
                            encode: {
                                x: "amount",
                                y: "product",
                            },
                            label: {
                                show: true,
                                position: "right",
                                formatter: (params) => {
                                    const amount = params.data[0]; // O valor numérico está na coluna "amount"
                                    return `${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`; // Formata como moeda
                                },
                                color: "#7C828A",
                            },
                            itemStyle: {
                                color: (params) => {
                                    const colorList = [
                                        "#6E6EF7",
                                        "#4040F2",
                                        "#3333D1",
                                        "#181894",
                                        "#0D0D54",
                                    ];
                                    return colorList[params.dataIndex % colorList.length];
                                },
                            },
                            barWidth: "50%", // Ajuste a largura das barras aqui
                        },
                    ],
                });

                // await fetchSeedAcompanhamentoAbrangenciaNivelBrasilTSE(eleicao);

                navigate("/repasses-federais", { replace: true });

                setTimeout(() => {
                    handleMapaZoomEDrag({ mapSvgRef: svgBrasilMapRef });
                }, 1500);

                // bloquearDuploCliqueEstado.current = false;
            } else {
                const currentEstadoSelectedId = estadoObj.id_uf_ibge;
                if (estadoQueryParam !== currentEstadoSelectedId) {
                    navigate(`/repasses-federais/?estado=${currentEstadoSelectedId}`, {
                        replace: true,
                    });
                }

                setTimeout(() => {
                    setStateLocal((state) => ({
                        ...state,
                        mostrarMapaDoBrasil: false,
                        mostrarMapaSvgEstadoClicado: true,
                        idDoEstadoClicado: currentEstadoSelectedId,
                        municipiosDoEstadoSelecionado: [],
                        localidade: {
                            ...localidade,
                            uf: estadoObj,
                            municipio: null,
                        },
                    }));

                    filtrarMunicipiosDoEstadoSelecionadoPorUF(estadoObj.uf);

                    //   clearInterval(intervalAbrangenciaBrasilRef.current);
                    //   intervalAbrangenciaBrasilRef.current = null;
                }, 100);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            //   eleicao,
            //   fetchSeedAcompanhamentoAbrangenciaNivelBrasilTSE,
            //   filtrarMunicipiosDoEstadoSelecionadoTSEConfigDataPorUF,
            handleMapaZoomEDrag,
            localidade,
            location.search,
        ]
    );

    // Função para lidar com o clique em um estado do mapa
    const handleEstadoClick = useCallback(
        async e => {
            // if (bloquearDuploCliqueEstado.current) return;

            // bloquearDuploCliqueEstado.current = true;

            const currentStateClickedId = e.target.id;

            const estadoObj = estados.find(
                (estado) =>
                    Number(estado.id_uf_ibge) ===
                    Number(currentStateClickedId.replace("unidade-federativa-", ""))
            );

            await sincronizaUseStatesDeEstadoUFSelecionado({
                estadoObj,
            });

            // Return a Promise that resolves when the function's execution is complete
            return Promise.resolve();

        },
        [
            estados,
            // sincronizaUseStatesDeEstadoUFSelecionado
        ]
    );

    const handleCidadeChange = useCallback(
        async (event) => {
            // Retirei o if que verifica o valor de event é empty

            // Retirei o JSON.parse pq o select pesquisável ja passa um objeto
            const municipioObj = event;
            //   // VER ZONAS esta ativo, deve desativar e prosseguir
            //   if (mostrarMapaSvgMunicipioClicado) {
            //     // eslint-disable-next-line no-use-before-define
            //     setStateLocal(state => ({
            //       ...state,
            //       mostrarMapaDoBrasil: false,
            //       mostrarMapaSvgEstadoClicado: true,
            //       mostrarMapaSvgMunicipioClicado: false,
            //       listaCandidatosApuracaoPorLocalidade: [],
            //       apuracaoPorCargoDoMunicipioSelecionadoTSE: {},
            //     }));

            //     // Aguarda o SVG ser carregado
            //     await new Promise(resolve => {
            //       const checkSVGInterval = setInterval(() => {
            //         if (svgEstadoMapRef.current) {
            //           clearInterval(checkSVGInterval);
            //           resolve();
            //         }
            //       }, 100);
            //     });
            //   }

            setStateLocal((state) => ({
                ...state,
                localidade: {
                    ...state.localidade,
                    municipio: {
                        ...municipioObj,
                    },
                },
            }));

            const query = new URLSearchParams(location.search);
            const municipioQueryParam = query.get("municipio");
            const estadoQueryParam = query.get("estado");

            if (municipioQueryParam !== municipioObj?.value) {
                navigate(
                    `/repasses-federais/?estado=${estadoQueryParam}&municipio=${municipioObj?.value}`,
                    { replace: true }
                );
            }

            //   filtrarZonasDoMunicipioSelecionadoTSEConfigDataPorCd(municipioObj?.cd);

            const pathSvgEstado = svgEstadoMapRef?.current?.getElementById(
                municipioObj?.value
            );

            const municipioSelecionado =
                svgEstadoMapRef.current.querySelectorAll(".selecionado");
            // const todosOsMunicipios =
			// 	svgEstadoMapRef.current.querySelectorAll("path");

            municipioSelecionado.forEach((p) =>
                p.classList.remove("selecionado", 
                    // "desfocado"
                )
            );
            // todosOsMunicipios?.forEach((p) => p.classList.add("desfocado"));

            pathSvgEstado?.classList.add("selecionado");
            // pathSvgEstado?.classList.remove("desfocado");
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            location.search,
            //   filtrarZonasDoMunicipioSelecionadoTSEConfigDataPorCd,
            localidade.municipio,
            svgEstadoMapRef,
            //   mostrarMapaSvgMunicipioClicado,
        ]
    );

    // Função para adicionar os listeners aos elementos do mapa
    const handleClickMapaBrasil = useCallback(
        (element) => {
            if (isEmpty(element)) return;

            const { children } = element || {};
            for (let i = 0; i < children.length; i += 1) {
                const child = children[i];
                const dataId =
                    child.getAttribute("data-id") || child.getAttribute("id");

                // Verifica se dataId é válido antes de usar startsWith
                if (
                    dataId && // Verificação adicionada
                    dataId.startsWith("municipio-") &&
                    (child.getAttribute("d") ||
                        child.getAttribute("points") ||
                        child.getAttribute("r"))
                ) {
                    // Remover todas as classes que começam com 'partido-'
                    const classesToRemove = Array.from(child.classList).filter(
                        (className) => className.startsWith("partido-")
                    );
                    classesToRemove.forEach((className) => {
                        child.classList.remove(className);
                    });

                    // const codigoMunicipio = dataId.replace('municipio-', '');

                    // if (valorParaMap === 'municipio-vai-para-segundo-turno') {
                    //     child.classList.add('municipio-vai-para-segundo-turno');
                    // } else if (valorParaMap === 'sem-ganhador-e-sem-segundo-turno') {
                    //     child.classList.add('sem-ganhador-e-sem-segundo-turno');
                    // } else if (valorParaMap) {
                    //     const partidoDoGanhadorSemAcentuacao = deburr(valorParaMap);
                    //     child.classList.add(
                    //     `partido-${partidoDoGanhadorSemAcentuacao.replace(/\s+/g, '')}`
                    //     );
                    // }
                } else if (
                    dataId && // Verificação adicionada
                    dataId.startsWith("unidade-federativa-") &&
                    (child.getAttribute("d") ||
                        child.getAttribute("points") ||
                        child.getAttribute("r"))
                ) {
                    if (!child.getAttribute("data-listener-added")) {
                        child.addEventListener("click", handleEstadoClick);

                        child.setAttribute("data-tooltip-id", "my-tooltip");

                        // child.addEventListener('mouseenter', handleMouseEnterDebounced);

                        // child.addEventListener('mouseleave', () => {
                        // handleMouseEnterDebounced.cancel();

                        // if (fetchAbortControllerTooltipRef.current) {
                        //     fetchAbortControllerTooltipRef.current.abort();
                        //     fetchAbortControllerTooltipRef.current = null;
                        // }

                        // setReactTooltipContent(null);
                        // });

                        // // Marca que os listeners foram adicionados
                        // child.setAttribute('data-listener-added', 'true');
                    }
                }

                // Chamada recursiva para percorrer os filhos deste elemento
                if (child.children && child.children.length > 0) {
                    handleClickMapaBrasil(child);
                }
            }
        },
        [
            // handleEstadoClick, handleMouseEnterDebounced
        ]
    );

    // Iniciar o handle do Mapa do Estado
    useEffect(() => {
        if (!svgBrasilMapRef.current || localidade.uf || localidade.municipio) return;

        const svgElement = svgBrasilMapRef.current;
        handleClickMapaBrasil(svgElement);

        handleMapaZoomEDrag({ mapSvgRef: svgBrasilMapRef });
    }, [
        mostrarMapaDoBrasil, abaRepasses
    ]);

    const originalCirculoLaranjaNeonOrderHover = useRef(new Map()); // Usar ref para persistência sem re-renderizar

    const handleMunicipioMouseEnter = useCallback((event) => {
        const parent = event.target.parentNode;
        const targetElement = event.target;

        if (parent && targetElement) {
            // Salva a posição original do elemento
            if (!originalCirculoLaranjaNeonOrderHover.current.has(targetElement)) {
                originalCirculoLaranjaNeonOrderHover.current.set(targetElement, Array.from(parent.children).indexOf(targetElement));
            }

            // Move o elemento para o final do nó pai (z-index mais alto)
            parent.appendChild(targetElement);

            // Remove qualquer círculo existente
            const circuloExistente = parent.querySelector(".circulo-halo-hover");
            if (circuloExistente) {
                circuloExistente.remove();
            }

            // Cria o círculo "halo"
            const bbox = targetElement.getBBox();
            const centerX = bbox.x + bbox.width / 2;
            const centerY = bbox.y + bbox.height / 2;
            const raio = Math.max(bbox.width, bbox.height) * 0.5;

            const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
            circle.setAttribute("cx", centerX);
            circle.setAttribute("cy", centerY);
            circle.setAttribute("r", raio.toString());
            circle.setAttribute("fill", "hsla(18, 92.20%, 70.00%, 0.30)");
            circle.setAttribute("stroke", "none");
            circle.setAttribute("class", "circulo-halo-hover");
            circle.style.pointerEvents = "none";

            // Adiciona o círculo após o elemento
            parent.appendChild(circle);
        }
    }, []); // Sem dependências externas

    const handleMunicipioMouseLeave = useCallback((event) => {
        const parent = event.target.parentNode;
        const targetElement = event.target;

        if (parent && targetElement) {
            // Remove o círculo "halo"
            const circuloExistente = parent.querySelector(".circulo-halo-hover");
            if (circuloExistente) {
                circuloExistente.remove();
            }

            // Retorna o elemento à posição original
            if (originalCirculoLaranjaNeonOrderHover.current.has(targetElement)) {
                const originalIndex = originalCirculoLaranjaNeonOrderHover.current.get(targetElement);
                const siblings = Array.from(parent.children);

                if (originalIndex < siblings.length - 1) {
                    parent.insertBefore(targetElement, siblings[originalIndex]);
                } else {
                    parent.appendChild(targetElement);
                }
            }
        }
    }, []); // Sem dependências externas

    // Função para lidar com o clique em um município do mapa
    const handleMunicipioClick = useCallback(
        (e, naoChamadoPeloRouter = true) => {
          if (isEmpty(e)) return;
      
          const currentMunicipioClickedId = e?.target?.id;

            // if (!isEmpty(localidade?.municipio) && currentMunicipioClickedId === localidade?.municipio?.value) return;

          const municipioObj = municipios.find(
                m => Number(m.value) === Number(currentMunicipioClickedId)
          );

          if (isEmpty(municipioObj)) return;
      
          setStateLocal((state) => ({
            ...state,
            localidade: {
              ...state.localidade,
              municipio: municipioObj,
            },
          }));
      
          const query = new URLSearchParams(location.search);
          const municipioQueryParam = query.get("municipio");
      
          if (naoChamadoPeloRouter && municipioQueryParam !== municipioObj?.value) {
            const estadoObj = estados.find(
                    (e) => e.uf.toLowerCase() === municipioObj.uf.toLowerCase()
            );
      
            navigate(
              `/repasses-federais/?estado=${estadoObj.id_uf_ibge}&municipio=${municipioObj.value}`,
              { replace: true }
            );
          }
      
            const municipioSelecionado =
                svgEstadoMapRef.current.querySelectorAll(".selecionado");

            // const todosOsMunicipios =
			// 	svgEstadoMapRef.current.querySelectorAll("path");

            municipioSelecionado.forEach((p) =>
                p.classList.remove("selecionado", 
                    // "desfocado"
                )
            );
        //   todosOsMunicipios.forEach((p) => p.classList.add("desfocado"));
      
            // Define o município atual como selecionado
            e.target.classList.add("selecionado");
            //   e.target.classList.remove("desfocado");

            // Move o path clicado para o final do nó pai (efeito z-index: 2)
            const parent = e.target.parentNode;
            parent.appendChild(e.target); // Remove e adiciona novamente
            
          // Remove qualquer círculo criado anteriormente
            const circuloExistente = svgEstadoMapRef.current.querySelector(".circulo-halo");
            if (circuloExistente) {
                circuloExistente.remove();
            }

            // Obtém o bounding box do path selecionado (coordenadas no sistema interno do SVG)
            const bbox = e.target.getBBox();
            const centerX = bbox.x + bbox.width / 2;
            const centerY = bbox.y + bbox.height / 2;

            // Calcula um raio dinâmico baseado no tamanho do path
            const raio = Math.max(bbox.width, bbox.height) * 0.8; // Ajuste o fator multiplicador conforme necessário

            // Cria o círculo "halo"
            const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
            circle.setAttribute("cx", centerX);
            circle.setAttribute("cy", centerY);
            circle.setAttribute("r", raio.toString()); // Aplica o raio calculado
            circle.setAttribute("fill", "hsla(18, 92.20%, 70.00%, 0.30)");
            circle.setAttribute("stroke", "none"); // Remover borda
            circle.setAttribute("class", "circulo-halo");
            circle.style.pointerEvents = "none";

            // Adiciona o círculo após o path selecionado.
            e.target.parentNode.appendChild(circle);
      
        },
        [
            municipiosDoEstadoSelecionado,
            location.search,
            svgEstadoMapRef,
            // localidade,
        ]
      );      

    const handleClickMapaEstado = useCallback(
        (element) => {
            if (isEmpty(element)) return;

            const { children } = element;
            for (let i = 0; i < children.length; i += 1) {
                const child = children[i];

                // Verifica se o elemento é relevante (path, polygon, circle)
                if (
                    child.getAttribute("d") ||
                    child.getAttribute("points") ||
                    child.getAttribute("r")
                ) {
                    //   const pathId = child.getAttribute('id');

                    if (!child.getAttribute("data-listener-added")) {
                        child.setAttribute("data-tooltip-id", "my-tooltip");

                        child.setAttribute('data-tooltip-id', 'my-tooltip');

                        child.addEventListener('click', handleMunicipioClick);

                        child.addEventListener('mouseenter', handleMunicipioMouseEnter);
                        child.addEventListener('mouseleave', handleMunicipioMouseLeave);

                        // const municipioObj =
                        //   municipiosDoEstadoSelecionado.find(
                        //     m => Number(m.value) === Number(pathId)
                        //   );

                        // child.addEventListener('mouseenter', () =>
                        //   handleMouseEnterDebouncedParaTooltipNivelAbrangenciaUnidadeFederativa(
                        //     municipioObj
                        //   )
                        // );
                        // child.addEventListener('mouseleave', () => {
                        //   handleMouseEnterDebouncedParaTooltipNivelAbrangenciaUnidadeFederativa.cancel();

                        //   if (fetchAbortControllerTooltipRef.current) {
                        //     fetchAbortControllerTooltipRef.current.abort();
                        //     fetchAbortControllerTooltipRef.current = null;
                        //   }

                        //   setReactTooltipContent(null);
                        // });

                        // Marca que os listeners foram adicionados
                        child.setAttribute('data-listener-added', 'true');
                    }
                }
                // Chamada recursiva mais eficiente para percorrer os filhos
                if (child.hasChildNodes()) {
                    handleClickMapaEstado(child);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            // handleMunicipioClick, 
            municipiosDoEstadoSelecionado,
            // abaRepasses,
            // eleicao
        ]
    );

    const handleAutoCompletSelectBuscarMunicipio = useCallback(
        async (event) => {
            const municipioObj = event;
            const query = new URLSearchParams(location.search);
            const estadoQueryParam = query.get('estado');
            const estadoObj = estados.find(
                (e) => e.uf.toLowerCase() === municipioObj.uf.toLowerCase()
            );

            if (estadoQueryParam !== estadoObj.id_uf_ibge) {
                await handleEstadoClick({
                    target: {
                        id: estadoObj.id_uf_ibge,
                    },
                });


                // await sincronizaUseStatesDeEstadoUFSelecionado({
                //     estadoObj,
                // });

                setTimeout(() => {

                    if (municipioObj && !isNull(svgEstadoMapRef.current)) {
                        handleMunicipioClick({
                            target: svgEstadoMapRef.current.getElementById(municipioObj.value),
                        });
                    }

                }, 1500);
            } else {
                // await handleEstadoClick({
                //   target: {
                //     id: estadoObj.id_uf_ibge,
                //   },
                // });

                if (municipioObj && !isNull(svgEstadoMapRef.current)) {
                    handleMunicipioClick({
                        target: svgEstadoMapRef.current.getElementById(municipioObj.value),
                    });
                }
            }


        },
        [location.search]
    );

    const handlePermitirObterLocalizacaoClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    reverseGeocode(latitude, longitude);
                },
                (error) => {
                    // console.error("Erro ao obter a localização:", error);
                }
            );
        } else {
            // console.error("Geolocalização não é suportada pelo navegador.");
        }
    };

    const reverseGeocode = async (latitude, longitude) => {
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();
            const stateName = data.address.state;
            const cityName = data.address.city || data.address.town || data.address.village;

            // console.log("Estado:", stateName);
            // console.log("Município:", cityName);

            // Mapear para os IDs do IBGE
            mapToIBGE(stateName, cityName);

        } catch (error) {
            // console.error("Erro na geocodificação reversa:", error);
        }
    };

    const mapToIBGE = (stateName, cityName) => {
        const normalizeString = (str) =>
            str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        const normalizedStateName = normalizeString(stateName);
        const normalizedCityName = normalizeString(cityName);

        const estado = estados.find(
            (estado) => normalizeString(estado.descricao) === normalizedStateName
        );

        if (estado) {
            // const idUfIbge = estado.id_uf_ibge;
            // console.log("ID da UF IBGE:", idUfIbge);

            const municipios = getMunicipios().filter(
                (municipio) => municipio.uf.toLowerCase() === estado.uf
            );

            const municipio = municipios.find(
                (municipio) => normalizeString(municipio.nome) === normalizedCityName
            );

            if (municipio) {
                // const idMunicipioIbge = municipio.value;
                // console.log("ID do Município IBGE:", idMunicipioIbge);

                handleAutoCompletSelectBuscarMunicipio(municipio);

                // Utilize os IDs conforme necessário
                setMostrarModal(false);
            } else {
                // console.log("Município não encontrado.");
            }
        } else {
            // console.log("Estado não encontrado.");
        }
    };

    // Função auxiliar para determinar o conteúdo a ser renderizado
    const renderBrasilContent = () => {
        if (mostrarMapaDoBrasil) {
            // Caso apresente algum erro ao renderizar o mapa apresenta uma msg
            if (carregando === 2) {
                return (
                    <div className='map-brasil--container'>
                        {/* Renderiza o SVG e associa a ref para poder acessá-lo no DOM */}
                        <MapaBrasilWrapper>
                            <div className='mensagemErroContainer'>
                                {/* <MensagemErro
                    padding='20px'
                    img={3}
                    boxWidth='100%'
                    imgWidth='200px'
                    >
                    <p>
                        Ops, não existem informações mapeadas para esse nível de
                        abrangência. Tente consultar se há resultados selecionando
                        outras regiões geográficas.
                    </p>
                    </MensagemErro> */}
                            </div>
                        </MapaBrasilWrapper>
                    </div>
                );
            }

            return (
                <div className="map-brasil--container">
                    {/* Renderiza o SVG e associa a ref para poder acessá-lo no DOM */}
                    <div className={carregando === 1 ? "carregando-mapa" : ""}>
                        <MapaBrasilWrapper
                            // $isMobile={isMobile} 
                            className='mapa-wrapper'
                        >
                            <MapaBrasilComMunicipios shareRef={svgBrasilMapRef} />
                        </MapaBrasilWrapper>
                        <MapaBrasilWrapper className="carregando-wrapper">
                            {/* <LoadingIcon /> */}
                        </MapaBrasilWrapper>
                    </div>
                </div>
            );
        }
        return null;
    };

    // Função auxiliar para determinar o conteúdo a ser renderizado
    const renderEstadoContent = () => {
        if (idDoEstadoClicado && mostrarMapaSvgEstadoClicado) {
            // Caso apresente algum erro ao renderizar o mapa apresenta uma msg
            if (carregando === 2) {
                return (
                    <div className="map-estado--container">
                        {/* Renderiza o SVG e associa a ref para poder acessá-lo no DOM */}
                        {/*<MapaEstadoWrapper>
            <div className='mensagemErroContainer'>
                 <MensagemErro
                padding='20px'
                img={3}
                boxWidth='100%'
                imgWidth='200px'
                >
                <p>
                    Ops, não existem informações mapeadas para esse nível de
                    abrangência. Tente consultar se há resultados selecionando
                    outras regiões geográficas.
                </p>
                </MensagemErro> 
            </div>
            </MapaEstadoWrapper> */}
                        Error
                    </div>
                );
            }

            // Verifica se o SVG correspondente ao estado clicado está disponível

            const svgData = estadosSVGs[idDoEstadoClicado];

            if (!svgData) {
                // Retorna null se não houver SVG disponível para o estado clicado
                return "";
            }

            // // Renderiza o SVG apenas se estiver disponível
            const SVGComponent = svgData.svg_component;

            const query = new URLSearchParams(location.search);
            const municipioQueryParam = query.get("municipio");

            return (
                <div className="map-estado--container">
                    <div className={carregando === 1 ? "carregando-mapa" : ""}>
                        <MapaEstadoWrapper className='mapa-wrapper'>
                            <MapaDragWrapper className='mapa__drag'>
                                {React.createElement(SVGComponent, {
                                    refSvg: svgEstadoMapRef,
                                    // className: "hidden",
                                    pathId: localidade?.municipio?.value || municipioQueryParam,
                                })}
                            </MapaDragWrapper>
                        </MapaEstadoWrapper>
                        <MapaEstadoWrapper className="carregando-wrapper">
                            {/* <LoadingIcon /> */}
                        </MapaEstadoWrapper>
                    </div>
                </div>
            );

            // if (carregando === 1) {
            //   return (
            //     <div className='map-estado--container'>
            //       {/* Renderiza o SVG e associa a ref para poder acessá-lo no DOM */}
            //       <MapaEstadoWrapper>
            //         <LoadingIcon />
            //       </MapaEstadoWrapper>
            //       <StyledBotoesMapa $isMobile={isMobile}>
            //         <InfoLocalidade localidade={localidade} />
            //         {localidade.uf ? (
            //           <BotaoVerMapaBrasil onClick={handleVoltar} />
            //         ) : null}
            //         {localidade.municipio ? <BotaoVerZonas /> : null}
            //       </StyledBotoesMapa>
            //       {/* <StyledMapa.BotaoVoltarMapa>Voltar</StyledMapa.BotaoVoltarMapa> */}
            //     </div>
            //   );
            // }
        }
        return null;
    };

    useEffect(() => {
        if (window.screen.width <= 480) {
            setIsTelaMobile(true);
        }

        // Caso o estado esteja selecionado ao carregar o mapa
        const query = new URLSearchParams(location.search);
        const estado = query.get("estado");

        if (estado && isEmpty(municipiosDoEstadoSelecionado)) {
            handleEstadoClick({
                target: {
                    id: estado,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // const id = 35;if()
        if (localidade.municipio)
            consultaListagemRepasses(localidade.municipio.value, anoRepasses);
        else if (localidade.uf)
            consultaListagemRepasses(localidade.uf.id_uf_ibge, anoRepasses);
    }, [localidade, anoRepasses]);
	
    // Iniciar o handle do Mapa do Estado
    useEffect(() => {
        if (
            !svgEstadoMapRef.current ||
            !mostrarMapaSvgEstadoClicado ||
            isEmpty(idDoEstadoClicado)
        ) {
            return;
        }

        const svgElement = svgEstadoMapRef.current;
        handleClickMapaEstado(svgElement);

        handleMapaZoomEDrag({ mapSvgRef: svgEstadoMapRef });

        // // Limpeza ao desmontar o componente
        // // eslint-disable-next-line consistent-return
        // return () => {
        //     // Aborta qualquer requisição pendente
        //     if (fetchAbortControllerTooltipRef.current) {
        //         fetchAbortControllerTooltipRef.current.abort();
        //     }
        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mostrarMapaSvgEstadoClicado, idDoEstadoClicado, abaRepasses]);

    // Executa quando o municipio é alterado ou selecionado, apenas quando municipio é null
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const municipio = query.get('municipio');

        if (
            municipio &&
            !isEmpty(municipiosDoEstadoSelecionado) &&
            //   isEmpty(listaCandidatosApuracaoPorLocalidade) &&
            !isNull(svgEstadoMapRef.current) &&
            carregando === 0 &&
            (isEmpty(localidade?.municipio) || (abaRepasses === 0 && !isEmpty(localidade?.municipio)))
        ) {
            handleMunicipioClick({
                target: svgEstadoMapRef.current.getElementById(municipio),
            }, false); // param naoChamadoPeloRouter false, pois foi chamado pelo router sim

			// svgEstadoMapRef.current.classList.remove('hidden');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		location.search,
		carregando,
		svgEstadoMapRef.current,
		municipiosDoEstadoSelecionado,
		abaRepasses,
	]);

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 797);

	const checkScreenSize = () => {
		if (window.innerWidth < 1291) {
			setIsSmallScreen(true);
		} else {
			setIsSmallScreen(false);
		}
	};

	useEffect(() => {
		checkScreenSize();
		window.addEventListener('resize', checkScreenSize);

		return () => {
			window.removeEventListener('resize', checkScreenSize);
		};
	}, []);

    const handleVoltarParaMapaBrasil = () => {
        sincronizaUseStatesDeEstadoUFSelecionado({
          estadoObj: null,
        });
        window.localStorage.setItem(
          'map-zoom',
          JSON.stringify(window.d3.zoomIdentity)
        );
      };

    // Função para ocultar municípios que não pertencem aos partidos especificados
    const removeEstilosDoMunicipioAoRetornarParaUnidadeFederativa = () => {
        if (svgEstadoMapRef.current) {
            const todosOsMunicipios =
                svgEstadoMapRef.current.querySelectorAll('path');
            todosOsMunicipios.forEach(p =>
                p.classList.remove('selecionado', 
                    // 'desfocado'
                )
            );

            // Remove qualquer círculo criado anteriormente
            const circuloExistente = svgEstadoMapRef.current.querySelector(".circulo-halo");
            if (circuloExistente) {
                circuloExistente.remove();
            }

            // // Define o atributo 'transform' no elemento <svg> para refletir
            // const svgBBox = svgEstadoMapRef.current.viewBox.baseVal;
            // const viewBoxWidth = svgBBox.width; // Largura total do viewBox
            // const reflectionTransform = `translate(${viewBoxWidth}, 0) scale(1)`;
            // svgEstadoMapRef.current.setAttribute('transform', reflectionTransform);

            // Remove transformações aplicadas diretamente ao estilo do SVG
            svgEstadoMapRef.current.style.transform = 'scale(1) translate(0, 0)';

            // Seleciona o grupo principal dentro do SVG
            const groupElement = svgEstadoMapRef.current.querySelector('g');
            if (!groupElement) return;

            // Define o atributo 'transform' para o valor padrão
            groupElement.setAttribute('transform', 'scale(0.0001,-0.0001)');

        }
    };

    const handleVoltarParaMapaEstado = () => {

        removeEstilosDoMunicipioAoRetornarParaUnidadeFederativa();

        setRepasses({});
        setLineChartOption({ //Gráfico de linhas
            tooltip: {
                trigger: "axis",
            },
            grid: {
                top: '5%',
            },
            xAxis: {
                type: "category",
                data: [],
                boundaryGap: false,
                axisLine: {
                    lineStyle: {
                        color: "#EAECEE",
                        width: 1,
                    },
                },
                axisLabel: {
                    color: "#7C828A",
                    fontSize: 12,
                },
            },
            yAxis: {
                type: "value",
                min: 0,
                max: 0,
                interval: 200000,
            },
            series: [
                {
                    data: [],
                    type: "line",
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0, color: 'rgba(0, 102, 255, 0.5)'
                                },
                                {
                                    offset: 1, color: 'rgba(0, 102, 255, 0)'
                                }
                            ],
                            global: false
                        }
                    },
    
                    smooth: false,
                    lineStyle: {
                        color: "#4040F2",
                        width: 2,
                    },
                    symbol: "circle",
                    symbolSize: 8,
                    color: "#2323BE",
                },
            ],
        });

        setBarChartOption({ //Gráfico de barras
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            dataset: {
                source: [
                    ["score", "amount", "product"],
                    [0, 0, "Impositivas"],
                    [0, 0, "Relator"],
                    [0, 0, "Comissão"],
                    [0, 0, "Bancada"],
                    [0, 0, "Individuais"],
                ],
            },
            grid: {
                left: "5%",
                right: "5%",
                top: "5%",
                bottom: "5%",
                containLabel: true,
            },
            xAxis: {
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#EAECEE",
                        width: 1,
                    },
                },
                axisLabel: {
                    show: false,
                    color: "#7C828A",
                    fontSize: 12,
                },
                splitLine: {
                    show: false,
                },
            },
            yAxis: {
                type: "category",
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#EAECEE",
                        width: 1,
                    },
                },
                axisLabel: {
                    formatter: (value) => value,
                    color: "#7C828A",
                    fontSize: 12,
                },
            },
            series: [
                {
                    type: "bar",
                    encode: {
                        x: "amount",
                        y: "product",
                    },
                    label: {
                        show: true,
                        position: "right",
                        formatter: (params) => {
                            const amount = params.data[0]; // O valor numérico está na coluna "amount"
                            return `${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`; // Formata como moeda
                        },
                        color: "#7C828A",
                    },
                    itemStyle: {
                        color: (params) => {
                            const colorList = [
                                "#6E6EF7",
                                "#4040F2",
                                "#3333D1",
                                "#181894",
                                "#0D0D54",
                            ];
                            return colorList[params.dataIndex % colorList.length];
                        },
                    },
                    barWidth: "50%", // Ajuste a largura das barras aqui
                },
            ],
        });  

        // eslint-disable-next-line react/prop-types
        const estadoObj = estados.find(
            estado => Number(estado.id_uf_ibge) === Number(localidade.uf.id_uf_ibge)
        );

        sincronizaUseStatesDeEstadoUFSelecionado({
            estadoObj,
        });
        
        navigate(`/repasses-federais/?estado=${localidade.uf.id_uf_ibge}`, {
            replace: true,
        });
    };      

    const setLocalidadeComparacaoOriginal = (object) => {
        setStateLocal(state => ({...state, localidadeComparacao: {...state.localidadeComparacao, ...object}}));
    };

    return (
        <>
            <ButtonVoltar />

            <Modal
                tipoRepasse={"repasse"}
                active={mostrarModal}
                setActive={setMostrarModal}
            >
                <fieldset className={Styles.modalBody}>
                    <div className={Styles.modalHeader}>
                        <button onClick={() => setMostrarModal(false)}>
                            <svg
                                width="20"
                                height="19"
                                viewBox="0 0 23 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line
                                    x1="1.35355"
                                    y1="0.646447"
                                    x2="22.3536"
                                    y2="21.6464"
                                    stroke="#5D7ABA"
                                ></line>
                                <line
                                    y1="-0.5"
                                    x2="29.6985"
                                    y2="-0.5"
                                    transform="matrix(-0.707107 0.707107 0.707107 0.707107 22 1)"
                                    stroke="#5D7ABA"
                                ></line>
                            </svg>
                        </button>
                        <p>permitir sua localização</p>
                    </div>

                    <div className={Styles.modalImg}>
                        <img src={localizacaoFigure} alt="ícone" />
                    </div>

                    <div className={Styles.modalInfo}>
                        <p>Permitir o acesso à sua localização nos ajuda a fornecer informações  relevantes para a sua região</p>
                        <button onClick={handlePermitirObterLocalizacaoClick}>Permitir</button>
                    </div>
                </fieldset>
            </Modal>

            <ModelGerarRelatorio mostrarModalRelatorio={mostrarModalRelatorio} setMostrarModalRelatorio={setMostrarModalRelatorio} localidade={localidade} localidadeComparacao={localidadeComparacao} anoConsulta={anoRepasses}/>

            <section className={`${mostrarModal ? Styles.blurred : ""}`}>
                <article>
                    <Cabecalho>
                        <TitleRow>
                            <h2>Repasse dos recursos federais</h2>
                            {/* <BaseButton
                                class={Styles.relatorioButton}
                                onClick={() => setMostrarModalRelatorio(true)}
                            >
                                gerar relatório
                            </BaseButton> */}
                        </TitleRow>
                    </Cabecalho>

                    <Fade>
                        <FiltroRepasses>
                            {!isTelaMobile ? (
                                <BaseButton
                                    active={stateLocal.abaRepasses === 0}
                                    onClick={() =>
                                        setStateLocal((state) => ({ ...state, abaRepasses: 0 }))
                                    }
                                >
                                    visão geral
                                </BaseButton>
                            ) : null}
                            {isTelaMobile ? (
                                <MenuSeletoFiltro
                                    name="estado"
                                    disabled={false}
                                    onChange={(event) => {
                                        const aba = Number(event.target.value);
                                        setStateLocal((state) => ({
                                            ...state,
                                            abaRepasses: aba
                                        }))
                                    }}
                                    value={stateLocal.abaRepasses}
                                    placeholder="Filtros"
                                >
                                    <option value={0}>visão geral</option>
                                    <option value={1}>comparação</option>
                                    {/* <option value={2}>rankings</option> */}
                                    <option value={3}>detalhar emendas</option>
                                </MenuSeletoFiltro>
                            ) : null}
                            {/* Botões reutilizáveis para outras opções */}
                            {!isTelaMobile ? (
                                <FiltroButtons>
                                    <Button
                                        active={stateLocal.abaRepasses === 1}
                                        onClick={() =>
                                            setStateLocal((state) => ({ ...state, abaRepasses: 1 }))
                                        }
                                    >
                                        comparação
                                    </Button>
                                    {/* <Button
                                        active={stateLocal.abaRepasses === 2}
                                        onClick={() =>
                                            setStateLocal((state) => ({ ...state, abaRepasses: 2 }))
                                        }
                                    >
                                        rankings
                                    </Button> */}
                                    <Button
                                        active={stateLocal.abaRepasses === 3}
                                        onClick={() =>
                                            setStateLocal((state) => ({ ...state, abaRepasses: 3 }))
                                        }
                                    >
                                        detalhar emendas
                                    </Button>
                                </FiltroButtons>
                            ) : null}
                        </FiltroRepasses>
                    </Fade>

                    {abaRepasses == 0 ? (
                        <>
                            <ContentWrapper>
                                <RepassesAside>

                                    <AsideInfo>
                                        <AsideBox>
                                            <img
                                                src={municipioIcon}
                                                alt="Ícone que representa pessoas"
                                                width="30"
                                                height="30"
                                            />
                                            <p>meu município</p>
                                        </AsideBox>

                                        <AsideTitle>
                                            <AutoCompleteSelectBurcarMunicipioWrapper>
                                                <AutoCompleteSelect
                                                    onChange={handleAutoCompletSelectBuscarMunicipio}
                                                    opcoes={municipios.sort(
                                                        (a, b) => a.label.localeCompare(b.label)
                                                    )}
                                                    label={'município'}
                                                    placeholder='Digite aqui seu município'
                                                    propriedadeNomeMunicipio={'nome'}
                                                    propriedadeNomeUnidadeFederativa={'uf'}
                                                    // disabled={municipiosDoEstadoSelecionado.length === 0}
                                                    value={localidade.municipio}
                                                    localidade={localidade}
                                                    estados={estados}
                                                    // sincronizaUseStatesDeEstadoUFSelecionado={
                                                    //     sincronizaUseStatesDeEstadoUFSelecionado
                                                    // }
                                                />
                                                {
                                                    localidade.municipio &&

													<p className={Styles.repassesAsideTitle}>
														{localidade.municipio.nome}
													</p>
												}
											</AutoCompleteSelectBurcarMunicipioWrapper>
										</AsideTitle>
									</AsideInfo>

                                    <AsideRightComponentes>
                                        <BotaoSelectAnoWrapper>
                                            <select value={anoRepasses} onChange={(e) => setStateLocal(state => ({...state, anoRepasses: parseInt(e.target.value)}))}>
                                                <option value={2025}>2025</option>
                                                <option value={2024}>2024</option>
                                                {/* <option value={2023}>2023</option>
                                                <option value={2022}>2022</option>
                                                <option value={2021}>2021</option>
                                                <option value={2020}>2020</option> */}
                                            </select>
                                        </BotaoSelectAnoWrapper>

                                        <AsideRightBox>
                                            <BarraZoom
                                                svgElement={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    mostrarMapaDoBrasil
                                                        ? svgBrasilMapRef
                                                        : mostrarMapaSvgEstadoClicado
                                                            ? svgEstadoMapRef
                                                            : null
                                                }
                                                onCallback={(t) => {
                                                    d3TranformMapaRef.current = t;
                                                }}

                                                localidade={localidade}
                                                qualMapaSeTrataParaZoomEDragLibD3LocalStorageString={'map-zoom'}
                                            />
                                        </AsideRightBox>
                                        {
                                            localidade.uf && !localidade.municipio ? (
                                                <BotaoVoltarNivel onClick={handleVoltarParaMapaBrasil}>VER MAPA BRASIL</BotaoVoltarNivel>
                                            ) : localidade.uf && localidade.municipio ? (
                                                <BotaoVoltarNivel onClick={handleVoltarParaMapaEstado}>VER MAPA ESTADO</BotaoVoltarNivel>
                                            ) : null
                                        }
                                    </AsideRightComponentes>

                                </RepassesAside>

                                <MapaWrapper>
                                    {renderBrasilContent()}
                                    {renderEstadoContent()}
                                </MapaWrapper>

								<AsideRightComponentesBottom>
									<AsideLista>
										{repasses ?
											repasses.demografia?.find(demo => demo.ano == 2024) ?
												<AsideListaItem>
													<img src={populacaoIcon} alt="Ícone que representa uma população" width="30" height="30" />
													<figcaption>
														<span>{repasses.demografia?.find(demo => demo.ano == 2024).populacao.toLocaleString()}</span>
														<p>população</p>
													</figcaption>
												</AsideListaItem>
												: 
                                                <AsideListaItem>
                                                    <img src={populacaoIcon} alt="Ícone que representa uma população" width="30" height="30" />
                                                    <figcaption>
                                                        <span></span>
                                                        <p>população</p>
                                                    </figcaption>
                                                </AsideListaItem>
											: null}
										{repasses ?
											<>
												<AsideListaItem>
													<img src={cifraoIcon} alt="Ícone que representa um cifrão" width="30" height="30" />
													<figcaption>
														<span>{repasses.valor_total_empenhado?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
														{isSmallScreen ? (
															<p>vlr. emp. emendas</p>
														) : (
															<p>valor empenhado de emendas</p>
														)}
													</figcaption>
												</AsideListaItem>

                                                <AsideListaItem>
                                                    <img src={cifraoIcon} alt="Ícone que representa um cifrão" width="30" height="30" />
                                                    <figcaption>
                                                        <span>{repasses.emendas_pagas?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                                        <p>emendas pagas</p>
                                                    </figcaption>
                                                </AsideListaItem>
                                            </>
                                            : null}

										{/* <AsideListaItem>
											<img src={cifraoIcon} alt="Ícone que representa um cifrão" width="30" height="30" />
											<figcaption>
												<span>R$ 2.034.431,04</span>
												{isSmallScreen ? (
													<p>trans. rep. federais</p>
												) : (
													<p>transferências de outros repasses federais</p>
												)}
											</figcaption>
										</AsideListaItem> */}
										{/* <AsideBottom>
											<p>dados do portal da transparência</p>
										</AsideBottom> */}
									</AsideLista>
									<AsideRightBoxCongressistasRanking>
										{/* <BarraZoom
											svgElement={
												// eslint-disable-next-line no-nested-ternary
												mostrarMapaDoBrasil
													? svgBrasilMapRef
													: mostrarMapaSvgEstadoClicado
														? svgEstadoMapRef
														: null
											}
											onCallback={(t) => {
												d3TranformMapaRef.current = t;
											}}
										/> */}

										{/* <CongressistasRankingCarousel
											isTelaMobile={isTelaMobile}
											congressistasRankingList={congressistasRankingListMock}
										/> */}
										{/* <CongressistasRanking>
											<CongressistasTitulo>
												Ranking de congressistas + repasses
											</CongressistasTitulo>
											<CongressistasLista>
												<CongressistasListaItem>
													<figure>
														<img src={congressista1} alt="#" />
													</figure>
													<figureCaption>
														<p>1º</p>
														<strong>Wilder Morais</strong>
														<p>PL/GO</p>
													</figureCaption>
												</CongressistasListaItem>
												{!isTelaMobile ? (
													<>
														<CongressistasListaItem>
															<figure>
																<img src={congressista2} alt="#" />
															</figure>
															<figureCaption>
																<p>2º</p>
																<strong>Flávia Morais</strong>
																<p>PDT/GO</p>
															</figureCaption>
														</CongressistasListaItem>
														<CongressistasListaItem>
															<figure>
																<img src={congressista3} alt="#" />
															</figure>
															<figureCaption>
																<p>3º</p>
																<strong>Adriana Accorsi</strong>
																<p>PT/GO</p>
															</figureCaption>
														</CongressistasListaItem>
													</>
												) : null}
											</CongressistasLista>
											<button>ver mais detalhes</button>
										</CongressistasRanking> */}
									</AsideRightBoxCongressistasRanking>
								</AsideRightComponentesBottom>

                            </ContentWrapper>

                            {/* <section className={Styles.repassesContainer}>
                                <BarraFiltrosPorLocalidade
                                    localidade={localidade}
                                    // municipiosDeTodosEstadosTSEConfigData={
                                    //     municipiosDeTodosEstadosTSEConfigData
                                    // }
                                    // municipiosDoEstadoSelecionadoTSEConfigData={
                                    //     municipiosDoEstadoSelecionadoTSEConfigData
                                    // }
                                    handleEstadoChange={handleEstadoChange}
                                    handleCidadeChange={handleCidadeChange}
                                    // sincronizaEstadosSvg={
                                    //     sincronizaUseStatesDeEstadoUFSelecionado
                                    // }
                                    estados={estados}
                                    municipiosDoEstadoSelecionado={municipiosDoEstadoSelecionado}
                                    sincronizaUseStatesDeEstadoUFSelecionado={
                                        sincronizaUseStatesDeEstadoUFSelecionado
                                    }
                                />
                            </section> */}

                            <section className={Styles.repassesCharts}>
                                <div className={Styles.chartBox}>
                                    <div className={Styles.chartHeader}>
                                        <header>
                                            <h3>Evolução das emendas pagas</h3>
                                        </header>
                                        <ReactECharts
                                            option={lineChartOption}
                                            style={{ width: "100%", height: "350px" }}
                                        />
                                    </div>

                                    <div className={Styles.chartHeader}>
                                        <header>
                                            <h3>Tipos de emendas</h3>
                                        </header>
                                    </div>

                                    <div className={Styles.echartsBox}>
                                        <div className={Styles.resizeChart}>
                                            <ReactECharts option={barChartOption} style={{ width: "90%", height: "350px" }} onChartReady={(chart) => {
                                                window.addEventListener("resize", chart.resize);
                                            }} /></div>
                                    </div>
                                </div>

                                {/* <div className={Styles.chartBox}>
                                    <div className={Styles.chartHeader}>
                                        <header>
                                            <h3>Transferências de repasses federais</h3>
                                        </header>
                                        <ReactECharts
                                            option={lineChartOption}
                                            style={{ width: "100%", height: "350px" }}
                                        />
                                    </div>

                                    <div className={Styles.chartHeader}>
                                        <header>
                                            <h3>Origem das transferências</h3>
                                        </header>
                                    </div>

<<<<<<< HEAD
									<div className={Styles.echartsBox}>
										<ReactECharts
											option={barChartOption}
											style={{ width: "100%", height: "350px" }}
										/>
									</div>
								</div> */}
							</section>
                            
							{/* <MesorregiaoContainer idMesorregiao={repasses.microrregiao?.mesorregiao?.id} /> */}

                            <div className={Styles.repassesFooter}>
                                <p>
                                    *Os dados populacionais apresentados foram obtidos do
                                    Instituto Brasileiro de Geografia e Estatística (IBGE),
                                    estimativas de 2023. Disponível em: www.ibge.gov.br. Os dados
                                    de repasses de emendas parlamentares apresentados foram
                                    obtidos de fontes oficiais do Governo Federal.
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            {abaRepasses === 1 ? <ComparativoEmendas
                                idMunicipioIbge={localidade.municipio?.value}
                                localidade={localidade}
                                municipios={municipios}
                                estados={estados}
                                setLocalidadeComparacaoOriginal={setLocalidadeComparacaoOriginal}
                                anoConsulta={anoRepasses}
                            />

                                : null}

                            {abaRepasses === 2 ? <Ranking /> : null}

                            {abaRepasses === 3 ? <DetalharEmendas idMunicipioIbge={localidade.municipio?.value} anoConsulta={anoRepasses} /> : null}
                        </>
                    )}
                </article>
            </section>
        </>
    );
};

export default RepassesFederais;